const state = () => {
    return {
        token: '',
        user_info: {},
    }
}

const mutations = {
    updateToken(state, data) {
        state.token = data
    },
    updateUserInfo(state, data) {
        state.user_info = data
    }
}

const actions = {
    setToken({commit}, name) {
        commit('updateToken', name)
    },
    setUserInfo({commit}, name) {
        commit('updateUserInfo', name)
    },
}

export default {
    namespaced: true, state, mutations, actions
}
