<template>
  <div style="line-height: 56px; display: flex; padding: 0 50px 0 30px;">
    <div style="flex: 1">
      <div class="title-item">{{ $store.state.menu.label }}</div>
    </div>
    <div>
      <!--<router-link to="/interview" class="interview-item"></router-link>-->
      <el-dropdown style="cursor: pointer">
        <div style="display: inline-block">
        <span class="nick-item">
          {{ user_info.nick }}
        </span>
          <i class="el-icon-arrow-down" style="margin-left: 5px"></i>
        </div>
        <el-dropdown-menu slot="dropdown" style="width: 100px; text-align: center">
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <router-link style="text-decoration: none; color: #666666" to="/setting">
              <span class="menu-item" @click="toPage('账号设置')">账号设置</span>
            </router-link>
          </el-dropdown-item>
          <div class="line-item"></div>
          <el-dropdown-item style="font-size: 14px; padding: 5px 0">
            <span class="menu-item" style="text-decoration: none" @click="logout">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: "ComHeader",
  data() {
    return {
      currentPathName: 'home',
      user_info: {
        nick: '管理员',
        // avatarUrl: 'https://sass-zp-1306193366.cos.ap-nanjing.myqcloud.com/admin/common/2024-02-20/7e86d233f5f40d7f5777230e17a86824.png',
      },
    }
  },
  created() {
    let user_info = store.state.user.user_info
    if (user_info) {
      this.user_info = user_info
    }
  },
  methods: {
    toPage(name) {
      this.$store.commit('menu/updateLabel', name)
    },
    logout() {
      this.$confirm('确定退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.commit('user/updateToken', '')
        this.$store.commit('user/updateUserInfo', {})
        this.$store.commit('menu/updateLabel', '')

        this.$router.push(`/login`)
      }).catch(() => {
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.title-item {
  font-size: 14px;
  color: #666666;
}

.nick-item {
  font-size: 14px;
  color: #666666;
  margin-left: 30px;
}

a {
  font-size: 14px;
  color: #666666;
  text-decoration: none;
}

.interview-item {
  padding: 10px 20px;
}

.interview-item:hover {
  padding: 10px 20px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #4F6FF0;
}

.line-attr {
  width: 100%;
  height: 1px;
  background-color: #F2F5FA;
}

.line-item {
  width: 100%;
  height: 4px;
  background-color: #F2F5FA;
}

/deep/ .el-dropdown-menu__item:hover {
  background-color: #4F6FF0 !important;

  a {
    color: #FFFFFF !important;
  }

  span {
    color: #FFFFFF !important;
  }
}

.menu-item {
  padding: 10px 20px;
  // background-color: #4F6FF0;
}
</style>
